:root{
		--poppins:"Poppins", "Roboto", sans-serif;
		--openSans:"Open Sans", "Roboto", sans-serif;
}

body::-webkit-scrollbar {
	display: none;
}

::-webkit-scrollbar {
	width: 5px;
}

::-webkit-scrollbar-thumb {
	background-color: hsl(226,7%,36%);
	border-radius: 5px;
}

a, a:visited, a:hover, a:focus {
	text-decoration: none;
	color: inherit;
}

.MuiFormHelperText-root.Mui-error {
	position: absolute;
	bottom: -20px;
}

body {
	position: relative;
}
